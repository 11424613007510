import React, { useState, useEffect } from "react";
import axios from "axios";
import Compra from "./Compra";

function ListaCompras() {
  const [compras, setCompras] = useState([]);
  const [email, setEmail] = useState("");
  const [esperando, setEsperando] = useState(false);
  const [dataStatus, setDataStatus] = useState("esperando");

  const [ultimaCompra, setUltimaCompra] = useState(localStorage.getItem("carrito"))

  const requestNegocios = async (Email) => {
    const respuesta = await axios.request("https://serverthunder.appsployall.com/hubspot/negociosasociados?email=" + Email).then(res => {
      res.data.length > 0 ?
        setDataStatus("listo")
        :
        setDataStatus("vacio")

      setCompras(res.data)
      console.log("fgdhfgh", res.data)
      setEsperando(false)
      return res.data
    })
      .catch(e => {
        setDataStatus("noEncontrado")

        setEsperando(false)
        return null
      })

    return respuesta
  }

  const getContact = async (e) => {

    setEsperando(true)

    e.preventDefault();

    setEmail(email)
    const respuesta = await requestNegocios(email)

    return respuesta
  }

  const handleEmail = (e) => {
    localStorage.setItem("correoElectronico", e.target.value);
    setEmail(e.target.value)
  }

  useEffect(() => {
    setEmail(localStorage.getItem('correoElectronico'))
    requestNegocios(localStorage.getItem('correoElectronico'))
  }, []);


  if (esperando) {
    return (
      <div>
        Esperando respuesta...
      </div>
    )
  }
  else {
    return (
      <div className="lista-compras">

        {dataStatus != "listo"? 
        (
          <div>
            <p className="titulo">Escribe tu mail para ver tus compras</p>
            <div>
              <form onSubmit={getContact}>
                <fieldset>
                  <label>Email</label>
                  <input onChange={handleEmail} type="email"></input>
                </fieldset>
                <button disabled={esperando} className={esperando ? "disabled" : "enabled"} type="submit"> Enviar </button>
              </form>
            </div>
          </div>
        )
        : null}


        {
          dataStatus === "noEncontrado" ? (
            <div className="lista-compras">
              <div className="no-compras">No hemos encontrado el contacto</div>
            </div>
          ) : dataStatus === "vacio" ? (
            <div className="lista-compras">
              <div className="no-compras">No hemos encontrado compras para el contacto</div>
            </div>
          ) : (
            <div className="lista-compras">
              {
                compras.length != 0 ?
                  compras.map(compra => {
                    return <Compra id={compra.id} props={compra.properties}></Compra>
                  })
                  :
                  <div className="no-compras">No hemos encontrado compras</div>
              }
            </div>
          )
             }

      </div>
    )


  }
}


export default ListaCompras;
