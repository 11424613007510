import { React, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCartShopping,
  faUser,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { CartContext } from '../contextos/CartContext';

function Footer() {

  const { cart } = useContext(CartContext);

  return (
    <footer>
      <div className="flex_container">
        <div className="span3">
          <span id="hs_cos_wrapper_thunder" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>THUNDER</h3></span>
          <div id="hs_cos_wrapper_thunder_text" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="thunder_text">METALTRONIC S.A. Punto de venta de fábrica directo de la marca THUNDER.</div>
        </div>
        <div className="span3">
          <span id="hs_cos_wrapper_our" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>NOSOTROS</h3></span>
          <span id="hs_cos_wrapper_left_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu" data-hs-cos-general-type="widget" data-hs-cos-type="simple_menu"><div id="hs_menu_wrapper_left_menu" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical" role="navigation" data-sitemap-name="" data-menu-id="" aria-label="Navigation Menu">
            <ul role="menu">
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="javascript:void(0);" role="menuitem" target="_self">Historia</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="javascript:void(0);" role="menuitem" target="_self">Catálogo</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="javascript:void(0);" role="menuitem" target="_self">Agencias</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="javascript:void(0);" role="menuitem" target="_self">Talleres</a></li>
            </ul>
          </div>
          </span>
        </div>
        <div className="span3">
          <span id="hs_cos_wrapper_category" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>CATEGORÍAS</h3></span>
          <span id="hs_cos_wrapper_right_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu" data-hs-cos-general-type="widget" data-hs-cos-type="simple_menu"><div id="hs_menu_wrapper_right_menu" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical" role="navigation" data-sitemap-name="" data-menu-id="" aria-label="Navigation Menu">
            <ul role="menu">
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="javascript:void(0);" role="menuitem" target="_self">Deportivas</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="javascript:void(0);" role="menuitem" target="_self">Doble Propósito</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="javascript:void(0);" role="menuitem" target="_self">Café Racer</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="javascript:void(0);" role="menuitem" target="_self">Utilitarias</a></li>
              <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="javascript:void(0);" role="menuitem" target="_self">Scooter</a></li>
            </ul>
          </div>
          </span>
        </div>
        <div className="span3">
          <span id="hs_cos_wrapper_contactos" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>CONTACTOS</h3></span>
          <span id="hs_cos_wrapper_contact_info" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
            <ul class="lightgreen">
              <li>Avellaneda N69 y Calle E3<br/>Quito, Ecuador.</li>
              <li>+(593) 98 754 1013<br/>+(593) 02 2480350</li>
              <li>ventas.digitales@thundermotos.com</li>
            </ul>
          </span>
        </div>
      </div>
      <div id="hs_cos_wrapper_copy" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="copy">© 2023 THUNDER MOTOS. DISEÑADO POR PLOY SAS.</div>
    </footer>
  );
}

export default Footer;
