import { React, useState, useContext, useRef } from "react";
import axios from "axios";
import PopUpPago from "./PopUpPago";
import { CartContext } from '../contextos/CartContext';

import JsonCiudades from '../data/listaPrecioEnvio.json'

function FormularioCompra() {

  const { ciudades } = JsonCiudades

  const { cart, removeFromCart, clearCart, removeOneArticleFromCart, addOneArticleFromCart } = useContext(CartContext);

  const [esperando, setEsperando] = useState(false);

  const [url, setUrl] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [requestId, setRequestId] = useState(null)
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    document: "",
    email: "",
    mobile: "",
    typedocument: "CI",
  });

  const [errorsList, setErrorsList] = useState([])

  const [valorEnvio, setValorEnvio] = useState(null)
  const [tiempoEstimado, setTiempoEstimado] = useState(null)
  const [envio, setEnvio] = useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    console.log(e.target.name, e.target.value);

    if (e.target.name === "envio") {
      e.target.value !== "retiro-local" ?
        setEnvio(true) :
        setEnvio(false)
    }

    if (e.target.name === "ciudad") {
      setValorEnvio(e.target.selectedOptions[0].getAttribute('valor'))
      setTiempoEstimado(e.target.selectedOptions[0].getAttribute('tiempo'))
    }
  };

  function validateNombre(value, tipo) {
    const regexNombre = /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/;

    console.log(value, tipo);

    if (!formData.typedocument == "RUC") {
      if (regexNombre.test(value)) {
        return null;
      } else {
        return "El " + tipo + " no es valido";
      }
    } else {
      return null;
    }
  }

  function validarCorreo(email) {
    var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email === "") {
      return "Por favor, ingrese su correo electrónico.";
    } else if (!re.test(email)) {
      return "El correo electrónico ingresado no es válido.";
    } else {
      return null;
    }
  }

  const validateCedula = (value, type) => {
    const cedulaLength = value.length;
    if (type === "CI") {
      if (cedulaLength !== 10) {
        return "Cedula debe tener 10 digitos para CI";
      }
    } else if (type === "RUC") {
      if (cedulaLength !== 13) {
        return "Cedula debe tener 13 digitos para Ruc";
      }
    } else if (type === "PPN") {
      if (cedulaLength < 4 || cedulaLength > 16) {
        return "Cedula debe tener entre 4 y 16 digitos para Pasaporte";
      }
    } else {
      return "Tipo de cedula invalida";
    }
  };

  function validarNumeroEcuador(numero) {
    // Expresión regular para validar el número de teléfono de Ecuador
    const patronNumeroEcuador = /^09\d{8}$/; //0955572700

    // Verificar si el número coincide con el patrón
    if (patronNumeroEcuador.test(numero)) {
      return null;
    } else {
      return "No es un número válido de Ecuador"; // No es un número válido de Ecuador
    }
  }

  function validateTerminos(value) {
    if (value) return null
    else return "No se aceptaron los terminos y condiciones"
  }

  /*
Cédula: 10 dígitos
Ruc: 13 dígitos
Pasaporte: De 4 a 16 caracteres
*/

  //validar numero de telefono

  /*
Los nombres y apelidos deben ser enviados por separado “name” y “surname” 
y no deben tener números ni caracteres especiales, deben tener únicamente letras (Incluye la ñ, tílde y espacio). 
*/

  /*
    ---------------------------------------------------------------------------------------------------------------------------------------
  ---------------------------------------------------------------------------------------------------------------------------------------
En caso de que el documento de identidad sea RUC:
Se debe enviar solo bajo nombre, la razón social o nombre comercial y en este caso se permite números.
  ---------------------------------------------------------------------------------------------------------------------------------------
    ---------------------------------------------------------------------------------------------------------------------------------------
*/

  // validar email

  //Se valida que la referencia no se repita en ningún caso, se valida vs transacciones pendientes, aprobadas y rechazadas en la base de datos.


  let errors = [];


  const handleSubmit = async (e) => {

    e.preventDefault();

    setEsperando(true);

    console.log("click envio")

    errors = [];

    
    if (Object.keys(JSON.parse(localStorage.getItem("carrito"))).length < 1)
      errors.push("Carrito Vacio");
    if (validateNombre(formData.name, "nombre"))
      errors.push(validateNombre(formData.name, "nombre"));
    if (validateNombre(formData.surname, "apellido"))
      errors.push(validateNombre(formData.surname, "apellido"));
    if (validateCedula(formData.document, formData.typedocument))
      errors.push(validateCedula(formData.document, formData.typedocument));
    if (validarNumeroEcuador(formData.mobile))
      errors.push(validarNumeroEcuador(formData.mobile));
    if (validarCorreo(formData.email))
      errors.push(validarCorreo(formData.email));
    if (validateTerminos(formData.comunicaciones))
      errors.push(validateTerminos(formData.comunicaciones))



    setErrorsList(errors)
    // CHECKEAR QUE NO HAYA ERRORES

    if (errors.length > 0) {
      console.log("error", errors);
      setEsperando(false);
    } else {

      localStorage.setItem("correoElectronico", formData.email);

      await axios.request('https://api.db-ip.com/v2/free/self').then(response => {

        let data = JSON.stringify({
          buyer: {
            name: formData.name,
            surname: formData.surname,
            email: formData.email,
            document: formData.document,
            documentType: formData.typedocument,
            mobile: parseInt(formData.mobile),
            city: formData.ciudad,
            envio: formData.envio
          },
          ipAddress: response.data.ipAddress,
          productos: localStorage.getItem("carrito"),
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://serverthunder.appsployall.com/placetopay/procesarPago", //"https://serverthunder.appsployall.com/server/placetopay/procesarPago"
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer pat-na1-fc43f8c1-67d1-4b9d-bf25-0e33c4d64669",
          },
          data: data,
        };

        console.log(data)

        axios
          .request(config)
          .then((response) => {

            setEsperando(false);

            console.log(JSON.stringify(response.data));

            setRequestId(response.data.requestId)
            setUrl(response.data.processUrl)

            localStorage.setItem('ultimaCompra', localStorage.getItem("carrito"))

            clearCart();
          })
          .catch((error) => {
            setEsperando(false);
            console.log(error)
            console.log(error.response.data);
            setErrorMessage(error.response.data)
          });


      })
        .catch(error => {
          console.log(e)
          setEsperando(false);
        })
    }
  };

  function transformarTexto(cadena) {
    cadena = cadena.replace(/_/g, ' ');

    const palabras = cadena.split(' ');

    for (let i = 0; i < palabras.length; i++) {
      palabras[i] = palabras[i].charAt(0).toUpperCase() + palabras[i].slice(1);
    }

    cadena = palabras.join(' ');

    return cadena;
  }

  return (
    <>

      {url ?
        <PopUpPago url={url} />
        :
        <div className="cont-form">
          <p>Formulario Compra</p>
          <form onSubmit={handleSubmit}>
            <fieldset>
              <div>
                <label htmlFor="name">Nombre:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="surname">Apellido:</label>
                <input
                  type="text"
                  id="surname"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange}
                />
              </div>
            </fieldset>

            <fieldset>
              <div>

                <label htmlFor="type-document">Tipo de Documento:</label>
                <select id="typedocument" name="typedocument" onChange={handleChange}>
                  <option>CI</option>
                  <option>RUC</option>
                  <option>PPN</option>
                </select>

              </div>
              <div>
                <label htmlFor="document">Documento:</label>
                <input
                  type="text"
                  id="document"
                  name="document"
                  value={formData.document}
                  onChange={handleChange}
                />
              </div>
            </fieldset>
            <fieldset>
              <div>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="mobile">Teléfono:</label>
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                />
              </div>
            </fieldset>

            <fieldset>
              <div>
                <label>Envio:</label>
                <select id="envio" name="envio" onChange={handleChange}>
                  <option value="retiro-local">Retiro por local</option>
                  <option value="otro">Otro</option>
                </select>
              </div>
              <div>
                <label>Ciudad:</label>
                <select id="ciudad" name="ciudad" onChange={handleChange}>
                  <option disabled selected >Selecciona una ciudad</option>
                  {Object.keys(ciudades).map((key, index) => (
                    <option key={index} value={ciudades[key].ciudad} valor={ciudades[key].valor} tiempo={ciudades[key].tiempo_de_entrega_horas} >
                      {transformarTexto(ciudades[key].ciudad)}
                    </option>
                  ))}
                </select>
              </div>
            </fieldset>
            {envio ? (
              <fieldset>
                <div>
                  <label>Valor del envio: ${valorEnvio}</label>
                </div>
                <div>
                  <label>Tiempo estimado: {tiempoEstimado} hs</label>
                </div>
              </fieldset>
            ) : null}

            <fieldset className="cont-terminos">
              <div>
                <div className="aceptar-terminos">
                  <input className="checkbox" autocomplete="off" type="checkbox" name="suscribirme" onChange={handleChange}></input>
                  <label>Si, deseo suscribirme al blog y novedades.</label>
                </div>
                <div>
                  <p>
                    THUNDER GO! se compromete a proteger y respetar tu privacidad, y
                    solo usaremos tu información personal para administrar tu cuenta
                    y proporcionar los productos y servicios que nos solicitaste. De
                    vez en cuando, nos gustaría ponernos en contacto contigo acerca
                    de nuestros productos y servicios, así como sobre otros
                    contenidos que puedan interesarte. Si aceptas que nos
                    comuniquemos contigo para este fin, marca la casilla a
                    continuación para indicar cómo deseas que nos comuniquemos:
                  </p>
                </div>
              </div>
            </fieldset>
            <fieldset className="cont-terminos">
              <div>
                <div className="aceptar-terminos">
                  <input className="checkbox" autocomplete="off" type="checkbox" name="comunicaciones" onChange={handleChange}></input>
                  <label>
                    Acepto recibir otras comunicaciones de THUNDER GO!.*
                  </label>
                </div>
                <div>
                  <p>
                    Puedes darte de baja de estas comunicaciones en cualquier
                    momento. Para obtener más información sobre cómo darte de baja,
                    nuestras prácticas de privacidad y cómo nos comprometemos a
                    proteger y respetar tu privacidad, consulta nuestra Política de
                    privacidad. Al hacer clic en Enviar, aceptas que THUNDER GO!
                    almacene y procese la información personal suministrada arriba
                    para proporcionarte el contenido solicitado.
                  </p>
                </div>
              </div>
            </fieldset>
            <div className="cont-button">
              <button disabled={esperando} className={esperando ? "disabled" : "enabled"} type="submit">Enviar</button>
            </div>
          </form>

          {errorsList.length > 0 ?

            <div className="lista-errores-form">
              <ul>
                {errorsList.map(errorCampo => {
                  return (<li>{errorCampo}</li>)
                })}
              </ul>
            </div>

            : null
          }
        </div>
      }

      {errorMessage ?
        <div className="error-formulario" ><p>{errorMessage}</p></div>
        : null
      }

    </>
  );
}

export default FormularioCompra;
