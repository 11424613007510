import { ReactComponentElement, useContext } from "react";
import { ProductsContext } from "../contextos/ProductsContext";

import Galeria from "./Galeria";

function Galerias() {

  const { productos } = useContext(ProductsContext)

  console.log(productos)

  return (
    <div className="galerias">
      {
        productos ?

          Object.keys(productos).map((categoria) => {

            console.log(productos[categoria])
            return(
              <Galeria categoria={categoria} productos={productos[categoria]}/>
            )
          })
          : <div>Cargando Productos</div>}
    </div>
  );
}

export default Galerias;
