import React, { useState, useEffect } from 'react'
import Producto from './Producto';
import { useNavigate } from "react-router-dom";

function Galeria({ categoria, productos }) {

    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 12;

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

    const [productosFilt, setProductosFilt] = useState(productos)
    const [tipos, setTipos] = useState([])
    const [tipoSelect, setTipoSelect] = useState("")

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const navigate = useNavigate();

    function handleClick(id) {
        navigate("/detalle?" + "id=" + id);
    }

    function handleActive(e) {

    const elementosActivos = document.querySelectorAll('.sub-familias .active');

    elementosActivos.forEach((elemento) => {
      elemento.classList.remove('active');
    });

    e.target.classList.add('active');
    }

    useEffect(() => {
        setCurrentPage(1)
        if (tipoSelect)
            setProductosFilt(productos.filter(producto => producto.properties.descripcion_sub_familia === tipoSelect));
        console.log(tipoSelect)
    }, [tipoSelect])

    useEffect(() => {
        const tiposSet = new Set();

        productos.map((producto) => {
            tiposSet.add(producto.properties.descripcion_sub_familia)
        })

        console.log("tipos", [...tiposSet])

        setTipos([...tiposSet])
    }, [])

    return (
        <div id={categoria} className="cont-galeria">
            <p className='cat-titulo'>{categoria}</p>
            {tipos.length>1 ?
                <div className="sub-familias" onClick={handleActive}>
                    {tipos.map((tipo) => {
                        return (
                            <button className={tipo} onClick={() => setTipoSelect(tipo)}>{tipo}</button>
                        )
                    })}
                </div>
                : null}
            <div className="pages-galeria">

            <ul className="pagination">
                    {Array.from({ length: Math.ceil(productosFilt.length / productsPerPage) }).map((_, index) => (
                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
                            {index + 1}
                        </li>
                    ))}
                </ul>

                <div className="galeria">
                    {productosFilt.slice(indexOfFirstProduct, indexOfLastProduct).map((producto) => (

                        <div
                            key={"producto" + producto.properties.hs_object_id}
                            //handleProductData("./motoTHUNDERbullet.webp", producto.properties.name, producto.properties.price)}
                            className="item_product">
                            <Producto
                                img={producto.properties.hs_images}
                                nombre={producto.properties.name}
                                precio={producto.properties.precio_tc}
                                tipo={producto.properties.descripcion_sub_familia}
                                etiqueta={producto.properties.etiqueta}
                                handleClick={() => handleClick(producto.properties.hs_object_id)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Galeria