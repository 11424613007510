import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { CartContext } from "../contextos/CartContext"

function DetalleProducto() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const { addToCart } = useContext(CartContext);

  const [producto, setProducto] = useState(null);

  const [caracteristicas, setCaracteristicas] = useState(null);


  function formatCurrency(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number)) {
      return 'Invalid input';
    }

    // Convert the number to a fixed-point number with 2 decimal places
    const formattedNumber = number.toFixed(2);

    // Add comma separators for thousands
    const parts = formattedNumber.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Add a dollar sign and return the formatted currency string
    return '$' + parts.join('.');
  }

  //https://serverthunder.appsployall.com/server/hubspot/productos
  useEffect(() => {
    console.log(id)
    fetch("https://serverthunder.appsployall.com/ecommerce/products?id=" + id, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + process.env.REACT_APP_HUBSPOT_API_KEY,
      }),
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        console.log(data)
        setProducto(data);
      }).catch(e => console.log("error set producto: " + id, e))
  }, []);


  return (
    producto ?
      <>
        <div className="cont-detalle">
          <div className="cont-img">
            <img src={producto.properties.hs_images} />
          </div>

          <div className="con-info">
            <p className="nombre">{producto.properties.name}</p>
            <p className="precio">{formatCurrency(parseInt(producto.properties.precio_tc))}</p>
            <div className="gris">
              <p className="descripcion">{producto.properties.description}</p>
              <p className="categoria">Categoria: {producto.properties.categoria} {producto.properties.descripcion_sub_familia}</p>
              <p className="modelo">Modelo: {producto.properties.modelos}</p>
              <p className="version">Version: {producto.properties.version_modelo}</p>
              <div className="caract-importantes" dangerouslySetInnerHTML={{ __html: producto.properties.caracteristicas_mas_importantes }}></div>
            </div>
            <button
              onClick={() => {

                addToCart(
                  {
                    id: producto.id,
                    hs_object_id: producto.properties.hs_object_id,
                    hs_sku: producto.properties.hs_sku,
                    description: producto.properties.description,
                    name: producto.properties.name,
                    price: producto.properties.price,
                    img: producto.properties.hs_images,
                    cant: 1,
                  }
                )
              }
              }
            >
              Agregar al Carrito
            </button>
          </div>
        </div>
        <div className="cont-branding">
          <img src="https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0000_piaggio.png" />
          <img src="https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0001_mtm.png" />
          <img src="https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/WEB/THUNDER%20MOTOS/LOGOS/logo-ALFAMETAL-horizontal-270x80.png" />
          <img src="https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0004_Capa-1.png" />
          <img src="https://7897721.fs1.hubspotusercontent-na1.net/hubfs/7897721/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0002_metaltronic_groupactual.png" />
        </div>
      </>
      : <></>
  )

}

export default DetalleProducto;
