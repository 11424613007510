import { React, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBell,
	faCartShopping,
	faUser,
	faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { CartContext } from '../contextos/CartContext';
import { ProductsContext } from "../contextos/ProductsContext";

function Header() {

	const { cart, productCant } = useContext(CartContext);

	const { menuMotos } = useContext(ProductsContext)

	const [menuActive, setMenuActive] = useState(false);

	

	Object.keys(cart).forEach((prod) => {
		return prod.cant
	})

	const handleTriggerClick = () => {
		setMenuActive(!menuActive);
	};

	const handleMenuClick = () => {
		const mainMenu = document.getElementById("main_menu");
		mainMenu.classList.toggle("active");
	};

	const formatear = () => {

		let menu = document.querySelector('span#hs_cos_wrapper_my_menu');
		let menuModelos = document.getElementById('main_menu');
		// Defino mi desencadenante de apertura de menú de modelos
		let id_menu = "{{ module.id_menu }}";
		let header = document.querySelector('header');
		let headerHeight = document.querySelector('header').offsetHeight;
		// Obtener todos los elementos li dentro del menú
		let menuItems = document.querySelectorAll('ul[role="menu"] li');
		// Obtener el ancho del dispositivo
		let anchoDispositivo = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		// Obtener referencias a los elementos
		let burgerMenu = document.getElementById("burger-menu");
		// Obtener el elemento "main_menu_column"
		let mainMenuColumn = document.querySelectorAll('.main_menu_column');
		let mainMenuColumns = document.querySelectorAll('.main_menu_column');
		// Obtener todos los elementos con la clase ".column_title"
		let columnTitles = document.querySelectorAll('.column_title');
		let columnModels = document.querySelectorAll('.models_column');

		const columnCategoryTitles = document.getElementsByClassName('column_title');
		const columnCount = columnCategoryTitles.length;
		const titleWidth = 100 / columnCount;

		// Obtener el elemento con ID "main_menu"
		const mainMenu = document.querySelector("#main_menu");
		// Obtener el elemento con ID "back_btn"
		const backButton = document.querySelector("#back_btn");

		// Obtén todos los elementos con la clase "model"
		let elements = document.querySelectorAll('.model');
		// Define el tiempo de retraso inicial
		let delay = 0.2;


		function menuInitialHeight() {
			//header.style.top = headerHeight + 'px';
			menuModelos.style.top = headerHeight + 'px';
			//menuModelos.style.paddingTop = '36px';
		}
		menuInitialHeight();

		function toggleMenu() {
			console.log(menu);
			menu.classList.toggle("active");
			//menuHeight();
		}
		burgerMenu.addEventListener("click", function () {
			toggleMenu();
		});

		function activateMenu(trigerMenu) {
			console.log(trigerMenu);
			// Agregar el evento click al elemento encontrado
			trigerMenu.addEventListener('click', function () {
				console.log("Click en Motos");
				// Acciones a realizar cuando se hace clic en el elemento "Motos"
				menuModelos.classList.toggle("active");
				//menuHeight();
				//obtenerContenedorConMasItems();
				//calcularCantidadModelEnAnchoPantalla();
				calcularCantidadModelEnAnchoPantalla();
			});
		}

		function backModelsMenu() {
			// Eliminar la clase "active" del elemento "main_menu"
			mainMenu.classList.remove("active");
		}

		function modelFade() {
			let columActive = document.querySelectorAll(".main_menu_column.active .model");
			// Itera sobre cada elemento y agrega la animación con el retraso incrementado
			columActive.forEach(function (element, index) {
				// Agrega la clase "fadeUp" al elemento
				element.classList.add('fadeUp');
				// Calcula el retraso para este elemento multiplicando el índice por el tiempo de incremento
				let elementDelay = (index + 1) * delay;
				// Agrega el retraso utilizando la propiedad de estilo CSS
				element.style.animationDelay = elementDelay + 's';
			});
		}

		function activateColumn(columnTitles, thisTitle) {
			// Recorrer los elementos "column_title"
			columnTitles.forEach(columnTitle => {
				// Obtener el elemento padre "main_menu_column"
				let parentColumn = thisTitle.parentNode;
				// Quitar la clase "active" de todos los elementos "main_menu_column"
				mainMenuColumns.forEach(menuColumn => {
					menuColumn.classList.remove('active');
				});
				// Agregar la clase "active" al elemento padre "main_menu_column" del elemento clickeado
				parentColumn.classList.add('active');
			});
			modelFade();
		}
		/*
			function obtenerContenedorConMasItems() {
				// Obtener todos los contenedores .models_column
				var containers = document.querySelectorAll('.models_column');
				// Variable para almacenar el contenedor con más elementos
				var containerWithMostItems;
				// Variable para almacenar la cantidad de elementos del contenedor con más items
				var maxItemCount = 0;
				// Recorrer cada contenedor
				containers.forEach(function(container) {
					// Obtener la cantidad de elementos .model dentro del contenedor actual
					var itemCount = container.querySelectorAll('.model').length;
					// Verificar si el contenedor actual tiene más elementos que el máximo actual
					if (itemCount > maxItemCount) {
						maxItemCount = itemCount;
						containerWithMostItems = container;
					}
				});
				// Imprimir el resultado
				console.log("El contenedor con más elementos .model tiene " + maxItemCount + " elementos.");
				console.log("El contenedor es:");
				console.log(containerWithMostItems);
			}
			function calcularCantidadModelEnAnchoPantalla() {
				// Obtener el ancho de un elemento ".model"
				var modelWidth = document.querySelector('.model').offsetWidth;
				// Obtener el ancho de la pantalla
				var screenWidth = window.innerWidth;
				// Calcular la cantidad de elementos ".model" que podrían caber en el ancho de la pantalla
				var quantity = Math.floor(screenWidth / modelWidth);
				// Imprimir el resultado
				console.log("En el ancho de la pantalla podrían caber hasta " + quantity + " elementos .model.");
			}
		*/

		function calcularCantidadModelEnAnchoPantalla() {
			// Obtener el ancho de un elemento ".model"
			var modelWidth = document.querySelector('.model').offsetWidth;
			// Obtener el ancho de la pantalla
			var screenWidth = window.innerWidth;
			// Calcular la cantidad de elementos ".model" que podrían caber en el ancho de la pantalla
			var quantity = Math.floor(screenWidth / modelWidth);
			// Obtener todos los contenedores .models_column
			var containers = document.querySelectorAll('.models_column');
			// Variable para almacenar el contenedor con más elementos
			var containerWithMostItems;
			// Variable para almacenar la cantidad de elementos del contenedor con más items
			var maxItemCount = 0;
			// Recorrer cada contenedor
			containers.forEach(function (container) {
				// Obtener la cantidad de elementos .model dentro del contenedor actual
				var itemCount = container.querySelectorAll('.model').length;
				// Verificar si el contenedor actual tiene más elementos que el máximo actual
				if (itemCount > maxItemCount) {
					maxItemCount = itemCount;
					containerWithMostItems = container;
					console.log("Entran todos, se debe hacer el menu wrap.");
				}
			});
			// Verificar si el contenedor con más elementos tiene más elementos que los que caben en el ancho de la pantalla
			if (maxItemCount > quantity) {
				// Agregar la clase "horizontal" al contenedor "#main_menu"
				console.log("No entran todos, se debe hacer el menu horizontal.");
				document.querySelector('#main_menu').classList.add('horizontal_flex');
			}
		}




		// Iterar sobre los elementos li para encontrar el que tiene el texto "Motos"
		for (let i = 0; i < menuItems.length; i++) {
			let menuItem = menuItems[i];
			if (menuItem.textContent === id_menu) {
				menuItem.classList.add("triger_menu");
				let trigerMenu = document.querySelector(".triger_menu");
				activateMenu(trigerMenu);
				modelFade();
				break; // Detener el bucle después de encontrar el elemento deseado
			}
		}

		// Posicionamiento de los columnTitles
		for (let i = 0; i < columnCount; i++) {
			columnCategoryTitles[i].style.width = `${titleWidth}%`;
			columnCategoryTitles[i].style.left = `${titleWidth * i}%`;
		}
		// Activación de los ColumnTitle y los ColumnModels
		columnTitles.forEach(columnTitle => {
			columnTitle.addEventListener('click', () => {
				activateColumn(columnTitles, columnTitle);
			});
		});

		// Agregar el evento de clic al botón
		backButton.addEventListener("click", function () {
			backModelsMenu();
		});


		/*********************************/


		function handleMenuActivation_() {
			// Obtener el elemento "#main_menu.active"
			var mainMenu_ = document.querySelector('#main_menu.active');
			// Obtener todos los elementos ".main_menu_column"
			var menuColumns_ = document.querySelectorAll('.main_menu_column');
			// Remover todas las clases "next" y "prev" de los elementos ".main_menu_column"
			menuColumns_.forEach(function (column_) {
				column_.classList.remove('next', 'prev');
			});
			if (mainMenu_) {
				// Obtener el elemento ".main_menu_column.active"
				var activeColumn_ = mainMenu_.querySelector('.main_menu_column.active');
				console.log(activeColumn_);
				if (activeColumn_) {
					// Obtener los elementos ".main_menu_column" hermanos
					var columns_ = Array.from(activeColumn_.parentNode.children);
					// Obtener el índice del elemento ".main_menu_column.active"
					var activeIndex_ = columns_.indexOf(activeColumn_);
					// Agregar la clase "next" al siguiente elemento
					if (activeIndex_ < columns_.length - 1) {
						var nextColumn_ = columns_[activeIndex_ + 1];
						nextColumn_.classList.add('next');
					}
					// Agregar la clase "prev" al elemento anterior
					if (activeIndex_ > 0) {
						var prevColumn_ = columns_[activeIndex_ - 1];
						prevColumn_.classList.add('prev');
					}
				}
			}
		}

		// Escuchar el evento "click" en los elementos ".main_menu_column"
		var menuColumns_ = document.querySelectorAll('.main_menu_column');
		menuColumns_.forEach(function (column_) {
			column_.addEventListener('click', handleMenuActivation_);
		});

		// Escuchar el evento "click" en el elemento "#main_menu"
		var mainMenu_ = document.querySelector('#main_menu');
		mainMenu_.addEventListener('click', handleMenuActivation_);
	}

	useEffect(() => {
		setTimeout(() => formatear(), 1000)
	}, [])

	return (
		<header className="header">
			<div className="header_logo">
				<a href="/">
					<img src="./logo-thunder.webp" alt="Thunder Logo" />
				</a>
			</div>

			<span id="hs_cos_wrapper_my_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" data-hs-cos-general-type="widget" data-hs-cos-type="menu">
				<div id="hs_menu_wrapper_my_menu" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="Default" data-menu-id="115201409801" aria-label="Navigation Menu">
					<ul role="menu" className="active-branch">
						<li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com" role="menuitem">Inicio</a></li>
						<li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/nosotros" role="menuitem">Nosotros</a></li>
						<li className="hs-menu-item hs-menu-depth-1 triger_menu" role="none" onClick={handleMenuClick}><a href="javascript:;" role="menuitem">Motos</a></li>
						<li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/tiendas" role="menuitem">Agencias</a></li>
						<li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://blog.thundermotos.com" role="menuitem">Blog</a></li>
						<li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/accesorios" role="menuitem">Accesorios</a></li>
						<li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/contacto" role="menuitem">Contacto</a></li>
					</ul>
				</div>
			</span>

			<div id="hs_cos_wrapper_main_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module">
				<section className={`main_menu horizontal_flex ${menuActive ? "active" : ""}`} id="main_menu">
					<button className="btn" id="back_btn" type="button" title="volver">
						<span className="back_icon_btn"></span>
					</button>
					{menuMotos ?
						Object.keys(menuMotos).map((categoria, i) => {
							return (
								<div className={i == 0 ? "main_menu_column active" : i == 1 ? "main_menu_column next" : "main_menu_column"} data-index={i}>
									<h4 className="column_title">
										{categoria}
									</h4>
									<div className="models_column" data-index={i}>
										{Object.keys(menuMotos[categoria]).map(modelo => {
											return (
												<div className="model fadeUp" id={menuMotos[categoria][modelo][0].id}>
													<h4 className="model_title">
														<a className="model_url" href={menuMotos[categoria][modelo][0].properties.hs_url} title={menuMotos[categoria][modelo][0].properties.name}>{menuMotos[categoria][modelo][0].properties.name}</a>
													</h4>
													<span className="model_price">${menuMotos[categoria][modelo][0].properties.precio_tc}</span>
													<a className="model_url" href={menuMotos[categoria][modelo][0].properties.hs_url} title={menuMotos[categoria][modelo][0].properties.name}>
														<img className="model_img" src={menuMotos[categoria][modelo][0].properties.hs_images} alt={menuMotos[categoria][modelo][0].properties.name} width="180" height="180" loading="lazy" sizes="(max-width: 180px) 100vw, 180px" />
													</a>
												</div>
											)
										})}

									</div>
								</div>
							)
						})
						: null
					}
				</section>
			</div>

			<div
				id="burger-menu"
				className="burger-menu"
				onClick={handleTriggerClick}
			>
				<div className="bar"></div>
				<div className="bar"></div>
				<div className="bar"></div>
			</div>

			<div className="header-iconos">
				<div>
					<a href="/miscompras">Mis Compras</a>
				</div>
				<div>
					<FontAwesomeIcon icon={faBell} />
				</div>
				<div className="carrito">
					{cart ? <div id="carrito-cant">{
						productCant
					}</div> : <></>}
					<a id="carrito-icon" href="/carrito">
						<FontAwesomeIcon icon={faCartShopping} />
					</a>
				</div>
			</div>
		</header>
	);
}

export default Header;
