import React from 'react'

function PopUpPago({ url }) {
  return (
    <div className='cont-pago-popup'>
      <div>
        <a href={url} target="_blank">
          <div className='button-pago'>link pago</div>
        </a>
      </div>
    </div>
  )
}

export default PopUpPago