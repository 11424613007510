import React,{useState, useEffect, useRef} from "react";
import DetalleProducto from "../components/DetalleProducto";

function DetallesProducto() {


  return (
    <div className="cont-body">
      <DetalleProducto />
    </div>
  );
}

export default DetallesProducto;
