import React from "react";

function Compra({ id, props }) {
  const productos = JSON.parse(props.resumencompra);

  const fecha = new Date(props.createdate)

  console.log(props)

  return (
    <div className="compra">
      <div className="fecha">Realizada el dia: {fecha.toLocaleDateString()}</div>
      <ul>
      {
        productos?
      productos.map((data) => {
        return (
          <li>
           {data.name} x {data.qty}/ PRECIO: {data.price} / TOTAL:{data.price * data.qty}
          </li>
        );
      })
    :
     null
    }
      </ul>
      <div className="estado">Estado: {props.dealstage}</div>
    </div>
  );
}

export default Compra;
