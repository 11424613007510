import React, { useState } from "react";
import Galerias from "../components/Galerias";
import CarrouselLogo from "../components/CarrouselLogo";
import { ProductsProvider } from "../contextos/ProductsContext"

function GaleriaProductos() {

  return (
    <div className="cont-body">
      <ProductsProvider>
        <Galerias />
      </ProductsProvider>
      <CarrouselLogo />
    </div>
  );
}

export default GaleriaProductos;
