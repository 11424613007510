import React, { useState, useEffect, useContext } from "react";
import ProductoCarrito from "./ProductoCarrito";
import { CartContext } from "../contextos/CartContext";

function Carrito() {
  const { cart, addToCart, removeFromCart, clearCart } =
    useContext(CartContext);

  let total = 0;

console.log("dfgdfsgfd", cart)

  return (
    <div className="cont-carrito">
      {Object.keys(cart).length>0 ? (
        Object.keys(cart).map((key) => {
          total += cart[key].cant * cart[key].price
          return (
            <div className="productos-select" key={"producto" + cart[key].id}>
              <ProductoCarrito producto={cart[key]} />
            </div>
          )
        })
      ) 
      : (
        <div className="no-compras">No hay compras pendientes</div>
      )}

{Object.keys(cart).length>0? <div className="total">Total: ${new Intl.NumberFormat().format(total)}</div> : null}
    </div>
  );
}

export default Carrito;
