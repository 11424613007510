import React, { useEffect } from "react";

function CarrouselLogo() {
  useEffect(() => {
    const carousel = document.querySelector('.carousel');
    const logos = document.querySelectorAll('.logo');
    const logoWidth = logos[0].offsetWidth;
    let currentIndex = 0;
  
    // Clonar los ítems al final del carrusel
    const cloneLogos = Array.from(logos).map(function(logo) {
      return logo.cloneNode(true);
    });
    cloneLogos.forEach(function(clone) {
      carousel.appendChild(clone);
    });
  
    // Ajustar el ancho del carrusel para mostrar al menos un logo en pantalla
    carousel.style.width = `${logoWidth * (logos.length + cloneLogos.length)}px`;
  
    const interval = setInterval(function() {
      currentIndex = (currentIndex + 1) % (logos.length);
      carousel.style.transform = `translateX(-${currentIndex * logoWidth}px)`;
    }, 3000);

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, []);
  
  return (
    <div className="carousel-container">
      <div className="carousel">
		<div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0004_Capa-1.png" alt="thundergo_0004_Capa-1"  width="200px"/>
		</div>
		<div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0002_metaltronic_groupactual.png" alt="thundergo_0002_metaltronic_groupactual"  width="200px"/>
		</div>
		<div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/logo-ALFAMETAL-horizontal-270x80.png" alt="logo-ALFAMETAL-horizontal-270x80"  width="200px"/>
		</div>
		<div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0001_mtm.png" alt="thundergo_0001_mtm"  width="200px"/>
		</div>
		<div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0000_piaggio.png" alt="thundergo_0000_piaggio"  width="200px"/>
		</div>
		<div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/moto%20planet%20logo%20gris.png" alt="moto planet logo gris"  width="200px"/>
		</div>
	    <div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0004_Capa-1.png" alt="thundergo_0004_Capa-1"  width="200px"/>
		</div>
        <div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0002_metaltronic_groupactual.png" alt="thundergo_0002_metaltronic_groupactual"  width="200px"/>
		</div>
        <div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/logo-ALFAMETAL-horizontal-270x80.png" alt="logo-ALFAMETAL-horizontal-270x80"  width="200px"/>
		</div>
        <div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0001_mtm.png" alt="thundergo_0001_mtm"  width="200px"/>
		</div>
        <div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/thundergo_0000_piaggio.png" alt="thundergo_0000_piaggio"  width="200px"/>
		</div>
        <div className="logo">
			<img src="https://www.thundermotos.com/hubfs/PLOY/WEB/THUNDER%20MOTOS/LOGOS/moto%20planet%20logo%20gris.png" alt="moto planet logo gris"  width="200px"/>
		</div>
      </div>
    </div>
  );
}

export default CarrouselLogo;
