import { React, useState } from "react";

function InfoPago() {

  return (
    <div className="info-form">
      <div>
      <img className="logo" src="https://static.placetopay.com/placetopay-logo.svg" />
      </div>
      <div>
      <img className="logo" src="./franquicias.png" />
      </div>
    </div>
  );
}

export default InfoPago;
