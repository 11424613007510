// CartContext.js
import React, { createContext, useState, useEffect } from 'react';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(
    localStorage.getItem('carrito')?
    JSON.parse(localStorage.getItem('carrito')):
    {}
  );
  

  let productCant = 0;

	Object.keys(cart).forEach((prod) => {
    console.log("asdasdsa", cart[prod].cant)
		productCant += cart[prod].cant
	})

  useEffect(() => {
    // Guardar el carrito en el almacenamiento local cada vez que cambie
    localStorage.setItem('carrito', JSON.stringify(cart));
  }, [cart]);

  // Funciones para agregar, eliminar y vaciar el carrito
  const addToCart = (item) => {

    const cartEdit = cart || {};

    if(cartEdit){
      if(cartEdit[item.id]){
        console.log(cartEdit[item.id].cant)
        cartEdit[item.id].cant ++
      }else{
        cartEdit[item.id] = item
      }
    }

    const cartElement = document.getElementById("carrito-icon")

    const cartCantElement = document.getElementById("carrito-cant")

    cartElement.classList.add("move")

    cartCantElement.classList.add("move")

    setTimeout( ()=>{
     cartElement.classList.remove("move")
     cartCantElement.classList.remove("move")
    }, 500 )

    setCart({...cartEdit});
  };

  const removeFromCart = (itemId) => {
    const cartEdit = cart
    delete cartEdit[itemId]
    setCart({...cartEdit});
  };

  const removeOneArticleFromCart = (itemId) => {
    const cartEdit = cart

    if(cartEdit[itemId].cant == 1){
      removeFromCart(itemId)
    }else{
      cartEdit[itemId].cant --
      setCart({...cartEdit})
    }

  };

  const addOneArticleFromCart = (itemId) => {
    const cartEdit = cart
    cartEdit[itemId].cant ++
      setCart({...cartEdit})
  };

  const clearCart = () => {
    setCart({});
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        productCant,
        addToCart,
        removeFromCart,
        clearCart,
        removeOneArticleFromCart,
        addOneArticleFromCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
