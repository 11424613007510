import React, { createContext, useState, useEffect } from 'react';

const ProductsContext = createContext();

const ProductsProvider = ({ children }) => {
  const [productos, setProductos] = useState({});
  const [categoryMotos, setCategoryMotos] = useState([])
  const [menuMotos, setMenuMotos] = useState([])


  const enlistarProductos = (items) => {

    const listaProductos = {}
    const listaCategoryMotos = {}
    let listaMenorPrecio = {}
    const listaMenuMotos = {}

    for (const item in items) {
      //const precio = productos[item].properties.precio_tc
      const id = items[item].id
      const { categoria, descripcion_sub_familia, precio_tc, modelos } = items[item].properties
      if (!listaProductos[categoria]) listaProductos[categoria] = []

      listaProductos[categoria].push(items[item])

      if (categoria == "MOTOS") {
        if (!listaCategoryMotos[descripcion_sub_familia]) {
          listaCategoryMotos[descripcion_sub_familia] = []
        }
        listaCategoryMotos[descripcion_sub_familia].push(items[item])

        if (!listaMenuMotos[descripcion_sub_familia]) {
          listaMenuMotos[descripcion_sub_familia] = {}
          listaMenuMotos[descripcion_sub_familia][modelos] = []
        }

        if (!listaMenuMotos[descripcion_sub_familia][modelos]) {
          listaMenuMotos[descripcion_sub_familia][modelos] = []
        }


        /*
        if (listaMenorPrecio[descripcion_sub_familia][modelos] < precio_tc)
        listaMenorPrecio[descripcion_sub_familia][modelos] = precio_tc

                */

        listaMenuMotos[descripcion_sub_familia][modelos].push(items[item])

      }

    }

    listaMenorPrecio = listaMenuMotos

    let precioMinimo= 1000000000000000;

    for (const categoria in listaMenorPrecio) {
      for (const modelo in listaMenorPrecio[categoria]) {
        for (const objeto of listaMenorPrecio[categoria][modelo]) {
          const precioTC = objeto.properties.precio_tc;
          if (precioTC < precioMinimo) {
            precioMinimo = precioTC;
            listaMenorPrecio[categoria][modelo] = [objeto];
          }
        }
      }
    }

    setCategoryMotos(listaMenuMotos)
    setMenuMotos(listaMenorPrecio)

    return listaProductos
  }

  useEffect(() => {

    try {
      fetch("https://serverthunder.appsployall.com/hubspot/productos", { //https://serverthunder.appsployall.com
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + process.env.REACT_APP_HUBSPOT_API_KEY,
        }),
      })
        .then((response) => { return response.json() })
        .then((data) => {
          console.log("response", data);
          setProductos(enlistarProductos(data));

        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <ProductsContext.Provider
      value={{
        productos,
        categoryMotos,
        menuMotos
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export { ProductsContext, ProductsProvider };
